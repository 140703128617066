import { useNavigate } from 'react-router-dom';
import '../App.css';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Typography, Box } from '@mui/material';

const SelectOrganization = () => {
  const navigate = useNavigate();

  const onSelectOrganization = (event: SelectChangeEvent<string>) => {
    navigate(`/${event.target.value}/form`);
  };

  return (
    <div className="flex h-full justify-center items-center flex-col gap-8">
      <Box component="section" sx={{ textAlign: 'center' }}>
        <Typography variant="h6"> Welcome!</Typography>
        <Typography variant="body1"> To start the process, please select your organization </Typography>
      </Box>
      <FormControl sx={{ m: 2, width: 500, minWidth: 120, justifyContent: 'center' }} size="medium">
        <InputLabel>Select Organizaiton</InputLabel>
        <Select defaultValue={''} sx={{ textAlign: 'center' }} label="Select Organizaiton" onChange={(e) => onSelectOrganization(e)}>
          <MenuItem value={'NY'}>New York</MenuItem>
          <MenuItem value={'NJ'}>New Jersey</MenuItem>
          <MenuItem value={'CT'}>Connecticut</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
export default SelectOrganization;
