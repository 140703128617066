import { CircularHeader } from '../components/circular-header';
import { AcctValidatorForm } from '../components/acct-validator-form';
import PageByOrgRenderer from './PageByOrgRenderer'

import '../App.css';

export const ExistingAcctValidator = () => {
  return (

    <PageByOrgRenderer path='form'>
      <div className="existing-acct-validator-section">
        <CircularHeader />
        <h2>Welcome!</h2>
        <p id="sub-header-instruction">Please enter the following information to assign you a ticket number</p>
        <div className="form-inputs-container">
          <AcctValidatorForm />
        </div>
      </div>
    </PageByOrgRenderer>
  );
};
