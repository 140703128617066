interface ILists {
  label: string;
  value: string;
  disabled?: boolean;
}

export const preferredLanguageList: Array<ILists> = [
  { label: 'Select Language', value: '', disabled: true },
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'French', value: 'Fre' },
  { label: 'Russian', value: 'ru' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Other', value: 'Other' },
];

export const genderList: Array<ILists> = [
  { label: 'Select Gender', value: '', disabled: true },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];

export const reasonsForVisit: Array<ILists> = [
  { label: 'Select reason for your visit', value: '', disabled: true },
  { label: 'Tickets', value: 'Tickets' },
  { label: 'Class', value: 'Class' },
  { label: 'Deactivation', value: 'Deactivation' },
  { label: 'Wellness', value: 'Wellness' },
  { label: 'Benefits', value: 'Benefits' },
  { label: 'Other', value: 'other' },
];
