import idgCircleLogo from '../images/IDG_logo_circular.png';
import { ReactElement } from 'react';
import '../App.css';

export const CircularHeader = (): ReactElement => {
  return (
    <div id="idg-logo-container" className=" flex justify-center">
      <img src={idgCircleLogo} alt="idg logo above form" />
    </div>
  );
};
